import React from 'react';
import logo from '../../images/logo.png';

const websiteOnrepair: React.FC = () => (
    <div className="container-fluid">
        <div className="d-flex justify-content-center" style={{ height: "100vh", backgroundColor: "#2BBCB5" }}>
            <img src={logo} alt="logo" className="logo" style={{ height: "500px" }} />
            <h1 style={{ marginTop: "500px", color:"white" }}>Site en maintenance <br/> contact@innov237.com </h1>
        </div>
    </div>
);



export default websiteOnrepair;