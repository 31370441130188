import React from 'react';
import Banner from '../../components/banner/Banner';
import ServiceCard from '../../components/serviceCard/ServiceCard';
import iconweb from '../../images/icon-web.png';
import icondesign from '../../images/icon-design.png';
import iconstrategy from '../../images/icon-strategy.png';
import iconhosting from '../../images/icon-hosting.png';
import iconmarketing from '../../images/icon-marketing.png';
import iconia from '../../images/icon-ia.png';
import iconformation from '../../images/icon-formation.png';
import iconserviceclient from '../../images/icon-service-client.png';
import videocourbe from '../../videos/vc.mp4';
import team from '../../images/team.jpg';
import seremoApp from '../../images/seremo.png';
import lugginApp from '../../images/luggin.png';
import OuiCaring from '../../images/ouiCaring.png';
import beauteAdom from '../../images/beauteAdom.png';
import consoInvest from '../../images/consoInvest.png';
import hqseShop from '../../images/hqseShop.png';


import './Home.css';
import { DividerVertical } from '../../components/dividerVertical/DividerVertical';
import AppPresentationCard from '../../components/appPresentationCard/AppPresentationCard';

var serviceData = [
    {
        icon: iconweb,
        title: "Développement de logiciels personnalisés",
        description: "Nous construisons des sites Web primés et des applications mobiles conçues pour offrir de la valeur et des résultats. Notre produit final personnalisé pour votre demande unique"
    },
    {
        icon: icondesign,
        title: "Conception graphique",
        description: "Nos concepteurs UX bien versés créent des expériences visuellement impressionnantes et fonctionnelles en ligne qui sont conçues avec votre public à l’esprit."
    },
    {
        icon: iconmarketing,
        title: "Marketing Digital",
        description: "Grâce au RÉFÉRENCE, au contenu, au marketing par courriel, aux annonces Facebook et bien plus encore, nous développons des stratégies qui développent votre entreprise et offrent une grande visibilité au monde."
    },
    {
        icon: iconstrategy,
        title: "Stratégie et aperçus",
        description: "Grâce à des recherches et des analyses approfondies, notre équipe d’experts web construit une stratégie numérique sur mesure qui correspond à votre marque."
    },
    {
        icon: iconhosting,
        title: "Hébergement et sécurité",
        description: "Avec des partenaires mondiaux, nous concevons une infrastructure avec les normes les plus élevées en matière de confidentialité et de sécurité des données."
    },
    {
        icon: iconia,
        title: "Artificial intelligence consulting",
        description: "Nous aidons les organisations à tirer parti des technologies d’apprentissage automatique et à créer des produits d’IA révolutionnaires. Nous vous aidons à être agile, intelligent et rapide dans votre processus de prise de décision."
    },
    {
        icon: iconserviceclient,
        title: "Services à la clientèle",
        description: "Nous travaillons avec vous pour maintenir votre site après son lancement, avec un soutien dédié pour s’assurer que les objectifs commerciaux sont atteints."
    },
    {
        icon: iconformation,
        title: "Formation technologique",
        description: "Notre équipe d’experts vous donnera les compétences dont vous avez besoin pour administrer soi-même votre système. AWS, Microsoft Azure, google cloud, ITIL4."
    },

];

var mobileAppData = [
    { img: seremoApp, title: "Seremoworld", description: "Application de transfert d’argent", link: "link" },
    { img: lugginApp, title: "LuggIn", description: "Application de voyage", link: "link" },
    { img: OuiCaring, title: "OuiCaring", description: "Application santé", link: "link" },
    { img: beauteAdom, title: "Beaute Adom", description: "Application beauté", link: "link" },
    { img: consoInvest, title: "ConsoInvest", description: "Application de vente", link: "link" },
    { img: hqseShop, title: "Hqse-Shop", description: "Application santé", link: "link" },
];


var webApp = [
    { img: "https://www.kevmax.com/min/portfolio/origame/new.jpg", title: "Origame", description: "Money transfer app", link: "link" },
    { img: "https://www.kevmax.com/min/portfolio/beauteadom/web%20et%20mobile.jpg", title: "beauteadom", description: "Travel app", link: "link" },
    { img: "https://www.kevmax.com/min/portfolio/nyanga/neww.jpg", title: "Nyanga", description: "Care App", link: "link" },
    { img: "https://www.kevmax.com/min/portfolio/youmoove_school/new.jpg", title: "Youmoove Etudiant", description: "Beauty App", link: "link" },
    { img: "https://www.kevmax.com/min/portfolio/beauteadom%20shop/web%20et%20mobile.jpg", title: "BeauteAdom Shop", description: "Shop App", link: "link" },
    { img: "https://www.kevmax.com/min/portfolio/kema/web%20et%20mobile.jpg", title: "Kema shop", description: "Care App", link: "link" },
];


const Home: React.FC = () => (
    <div>
        <Banner></Banner>
        <div className="container-fluid" id="services">
            <div className="row device-row  p-2 d-flex align-items-center justify-content-center">
                <div className="expertise-title">
                    Nous fournissons des services de qualité supérieur <br />
                    Basés sur les nouvelles technologie
               </div>
                <div className="div-letf-dot"></div>
            </div>
        </div>

        <div className="services-row container-fluid">
            <div className="row pt-5">
                {serviceData.map((data) => (
                    <div className="col-md-3">
                        <ServiceCard
                            icon={data.icon}
                            title={data.title}
                            description={data.description}
                        >
                        </ServiceCard>
                    </div>
                ))}

            </div>
            <DividerVertical></DividerVertical>
        </div>

        <div className="footer conntainer-fluid">
            <DividerVertical></DividerVertical>
            <div className="container pb-4 pt-5 d-flex align-items-center justify-content-center">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h1 className="pb-5">Travailler pour bâtir votre succès</h1>
                        <p style={{ color: "#80889B;", fontSize: "15px" }}>
                            Depuis plus de 5 ans, innov237 se consacre à fournir les meilleurs services de conception web, de développement, d’hébergement et de marketing numérique. Avec nous, votre succès est notre succès et nous travaillons avec vous pour trouver des solutions en ligne puissantes qui correspondent à vos besoins.
                     </p>
                    </div>
                    <div className="col-md-6">
                        <video loop={true} style={{ width: '100%' }} className="pt-5 pb-3 banner-image2" autoPlay={true} src={videocourbe}></video>
                        <p style={{ color: "#80889B;", fontSize: "15px" }}>
                            <a href="#contact">  <button className="btn btn-more w-100">En savoir plus</button></a>
                        </p>
                    </div>

                </div>

            </div>
            <DividerVertical></DividerVertical>
        </div>

        <div className="footer conntainer-fluid" style={{ backgroundColor: "#E3F1FC" }}>
            <DividerVertical></DividerVertical>
            <div className="container pb-4 pt-5 d-flex align-items-center justify-content-center" id="about">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <img src={team} alt="logo" style={{ width: "100%" }} className="pt-5 pb-3" />
                    </div>
                    <div className="col-md-6">
                        <h1 className="pb-5">Une équipe professionnelle &amp; travailleuse</h1>
                        <p style={{ color: "#80889B;", fontSize: "15px" }}>
                            Partenariat avec un chef de file de l’industrie familiale et qui se concentre uniquement sur l’aide
                            clients se développent et atteindre le succès est une combinaison gagnante. L’équipe de
                            innov237 comprend que chaque client a une histoire différente et unique
                            objectifs numériques. Indépendamment de la taille de l’entreprise, de l’industrie ou de la technologie.
                        </p>
                        <p style={{ color: "#80889B;", fontSize: "15px" }}>
                            notre équipe talentueuse a fait ses preuves en offrant des
                            des solutions numériques qui produisent des résultats positifs pour les entreprises
                            le monde.
                     </p>
                    </div>

                </div>

            </div>
            <DividerVertical></DividerVertical>
        </div>


        <div className="realisations" id="portfolio">
            <DividerVertical></DividerVertical>
            <div className="container  pt-5 pb-5 d-flex align-items-center justify-content-center">
                <div className="row align-items-start">
                    <div className="col-md-12">
                        <h1 style={{ color: "black" }}>Développement de site &amp; <br /> D'applications Web</h1>
                        <p className="mt-2 pt-2 pb-5" style={{ color: "black" }}> Nos services de développement de site Web sont adaptés pour offrir de la valeur à votre entreprise. Des implémentations simples de plate-forme aux refontes complètes, nous réunissons plusieurs équipes pour construire une solution qui fonctionne pour vous. </p>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            {webApp.map((data) => (
                                <div className="col-md-4">
                                    <AppPresentationCard image={data.img} title={data.title} description="" link="" ></AppPresentationCard>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="realisations conntainer-fluid">
            <div className="container pt-5 pb-5 d-flex align-items-center justify-content-center">
                <div className="row align-items-start">
                    <div className="col-md-12">
                        <h1 style={{ color: "black" }} className="title">Mobile Application <br /> Android & Ios </h1>
                        <p className="mt-2 pt-1 pb-5" style={{ color: "black" }}> Nos services de développement de site Web sont adaptés pour offrir de la valeur à votre entreprise. Des implémentations simples de plate-forme aux refontes complètes, nous réunissons plusieurs équipes pour construire une solution qui fonctionne pour vous.</p>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            {mobileAppData.map((data) => (
                                <div className="col-md-4">
                                    <AppPresentationCard image={data.img} title={data.title} description={data.description} link="" ></AppPresentationCard>
                                </div>
                            ))};
                        </div>
                    </div>
                </div>
            </div>
            <DividerVertical></DividerVertical>
        </div>
    </div>
);

export default Home;