import React from 'react';

const Portfolio:
  React.FC = () => (
    <div>
      porfolio
    </div>
  );

  export default Portfolio;

