import React from 'react';
import './Banner.css';
import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

const Banner: React.FC = () => (

    <div className="banner-div container-fluid">
      
        <div className="row">
            <div className="col-md-8">
                <OwlCarousel
                    className="owl-theme"
                    items={1}
                    dots={true}
                    dotClass="dot-div"
                    dotsSpeed={true}
                    autoplay={true}
                    autoplaySpeed={500}
                    loop={true}
                >
                    <div className="item">
                        <div className="slider-content">
                            <h1 className="hero__title">Vous pensez et nous réalisons <br />au delà de vos attentes</h1>
                            <div className="hero__subTitle">
                                Nous convertissons votre idée en un logiciel personnalisé solide, sûr et fiable grâce à nos services de développement logiciel à cycle complet, notamment:
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="slider-content">
                            <h1 className="hero__title">Agence de développement <br /> de logiciels personnalisés</h1>
                            <div className="hero__subTitle">
                                Nous sommes spétialisé dans le developpement web et mobile, le marketing numérique, dans la conception graphique (Logo, RollUp, Flyer,...).
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
                <div className="contact-usBtn pb-3 pt-5">
                    <a href="#contact"><button className="btn btn-more">Contactez nous</button></a>
                </div>
            </div>
            <div className="col-md-4 d-flex justify-content-center p-0">
                <img src="https://doublslash.com/assets/posts/octocat.png" alt="" style={{ width: '100%' }} className="p-2 banner-image" />
            </div>
        </div>

        {/* <div className="container-fluid">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" preserveAspectRatio="none" viewBox="0 0 1680 40" className="position-absolute width-full z-1" style={{ bottom: "-1px;" }}><path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#fff"></path></svg>
        </div> */}
    </div>
);

export default Banner;